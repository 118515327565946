<template>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div class="mb-3">
                <h5 class="card-title">
                    {{ caption.ucFirst() }} <span class="text-muted fw-normal ms-2">({{ pager.total }})</span>
                </h5>
            </div>
        </div>

        <div class="col-md-6">
            <div
                    class="d-flex flex-wrap align-items-center gap-2 mb-3"
                    :class="{'justify-content-between': searchable, 'justify-content-end': !searchable}"
            >
                <div v-if="searchable">
                    <b-input-group>
                        <b-input-group-text class="bg-white border-right-0" :class="{focused: searchFocused}">
                            <i class="fas fa-search"></i>
                        </b-input-group-text>
                        <input class="form-control border-left-0" type="text" v-model="searchDebounce" :placeholder="$t('base.search').ucFirst()" @focusin="searchFocused = true" @blur="searchFocused = false" />
                    </b-input-group>
                </div>
                <div>
                    <b-button v-if="this.sortable" variant="success" class="me-2" @click="saveSorting">
                        <i class="fas fa-save me-1"></i>
                        <span>
                            {{ $t('base.save_sorting').ucFirst() }}
                        </span>
                    </b-button>
                    <router-link
                            v-if="createLink && can(permission + '.create')"
                            :to="translatedCreateLink"
                            class="btn btn-primary"
                    >
                        <div class="d-flex align-items-center">
                            <i class="bx bx-plus me-1"></i>
                            <span>{{ $t('base.add_new').ucFirst() }}</span>
                        </div>
                    </router-link>
                </div>
<!--
                <div class="dropdown">
                    <a
                            aria-expanded="false"
                            class="btn btn-link text-muted py-1 font-size-16 shadow-none dropdown-toggle"
                            data-bs-toggle="dropdown"
                            href="#"
                            role="button"
                    >
                        <i class="bx bx-dots-horizontal-rounded"></i>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end">
                        <li><a class="dropdown-item" href="#">Action</a></li>
                        <li><a class="dropdown-item" href="#">Another action</a></li>
                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                </div>
-->
            </div>
        </div>
    </div>
    <!-- end row -->

    <div class="row">
        <div class="col-lg-12">
            <div class="">
                <div :class="{'table-responsive': responsive}">
                    <table
                            :id="id"
                            :class="tableClasses"
                            class="table project-list-table table-nowrap align-middle"
                    >
                        <thead :class="headClasses">
                            <tr :class="headRowClasses">
                                <th v-if="selectable" class="ps-4" scope="col" style="width: 50px">
                                    <div class="form-check font-size-16">
                                        <input
                                                v-model="selectAll"
                                                :id="'select-all-' + _.uid"
                                                class="form-check-input"
                                                type="checkbox"
                                        />
                                        <label
                                                class="form-check-label"
                                                :for="'select-all-' + _.uid"
                                        ></label>
                                    </div>
                                </th>
                                <th scope="col" v-for="(field, key) in fields" :key="key" class="no-wrap" :class="{'column-actions': field.key === 'actions'}">
                                    <div class="d-flex align-items-center" :class="getThClass(field.thClass, field.key)" @click="changeSort(field.key)">
                                        <span>{{ field.label }}</span>
                                        <span v-if="!sortingDisabled && checkSorting(field.key)" class="sort" :class="sortClass(field.key)" :title="sortLabel(field.key)"></span>
                                    </div>
                                </th>
                            </tr>
                        </thead>

                        <draggable
                            v-if="sortable"
                            :class="tbodyClass"
                            v-bind="tbodyTrAttr"
                            tag="tbody"
                            v-model="elements"
                            item-key="id"
                        >
                            <template #item="{element, index}">
                                <tr :key="index">
                                    <th v-if="selectable" scope="row" class="ps-4">
                                        <div class="form-check font-size-16">
                                            <input
                                                v-model="element.selected"
                                                type="checkbox"
                                                class="form-check-input"
                                                :id="`select-${_.uid}-${element[primaryKey]}`"
                                                @change="checkSelectAll"
                                            />
                                            <label
                                                class="form-check-label"
                                                :for="`select-${_.uid}-${element[primaryKey]}`"
                                            ></label>
                                        </div>
                                    </th>
                                    <td v-for="(field, fieldKey) in fields" :key="fieldKey" :class="getTdClass(field.tdClass, field.key)">
                                        <slot :name="field.key" :item="element" :field="field" :value="element[field.key]" :methods="{ destroy: destroy }">
                                            <template v-if="field.key !== 'actions'">
                                                <template v-if="sortable && fieldKey === 0">
                                                    <i class="fas fa-arrows-alt me-2 cursor-move opacity-50"></i>
                                                </template>
                                                <template v-if="typeof element[field.key] === 'boolean'">
                                                    <i v-if="element[field.key] === true" class="fa fas fa-check-circle text-success"></i>
                                                    <i v-else class="fa fas fa-times-circle text-danger"></i>
                                                </template>
                                                <template v-else>
                                                    <template v-if="field.formatter">
                                                        <template v-if="typeof field.formatter(element[field.key], fieldKey, element) === 'boolean'">
                                                            <i v-if="field.formatter(element[field.key], fieldKey, element) === true" class="fa fas fa-check-circle text-success"></i>
                                                            <i v-else class="fa fas fa-times-circle text-danger"></i>
                                                        </template>
                                                        <template v-else>
                                                            {{ field.formatter(element[field.key], fieldKey, element) }}
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        {{ element[field.key] }}
                                                    </template>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <ul class="list-inline mb-0 column-actions no-wrap">
                                                    <li v-if="editable" class="list-inline-item">
                                                        <router-link
                                                            v-if="can(permission + '.update') && editLink"
                                                            :to="getEditLink(element.id)"
                                                            :title="$t('base.edit').ucFirst()"
                                                            class="px-2 text-primary"
                                                        >
                                                            <i class="bx bx-pencil font-size-18"></i>
                                                        </router-link>
                                                    </li>
                                                    <li v-if="deletable" class="list-inline-item">
                                                        <a
                                                            v-if="can(permission + '.delete')"
                                                            @click.prevent="destroy(element.id)"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="top"
                                                            :title="$t('base.delete').ucFirst()"
                                                            class="px-2 text-danger cursor-pointer"
                                                        ><i class="bx bx-trash-alt font-size-18"></i
                                                        ></a>
                                                    </li>
                                                </ul>
                                            </template>
                                        </slot>
                                    </td>
                                </tr>
                            </template>
                        </draggable>

                        <tbody v-if="!sortable || loading" :class="tbodyClass" v-bind="tbodyTrAttr">
                            <tr v-if="loading" :class="tbodyTrClass">
                                <td :colspan="fields.length + 1" class="text-center">
                                    <b-spinner type="grow"></b-spinner>
                                </td>
                            </tr>

                            <tr v-else-if="!loading && !elements.length && (emptyHtml || emptyText) && !search && this.showEmpty">
                                <td :colspan="fields.length + 1">
                                    <div v-if="emptyHtml" v-html="emptyHtml"></div>
                                    <div class="text-center" v-else-if="emptyText">
                                        <i class="fa fas fa-exclamation-triangle text-warning me-2"></i>
                                        {{ emptyText }}
                                    </div>
                                </td>
                            </tr>

                            <tr v-else-if="!loading && !elements.length && (emptyFilteredHtml || emptyFilteredText) && search  && this.showEmpty">
                                <td :colspan="fields.length + 1">
                                    <div v-if="emptyFilteredHtml" v-html="emptyFilteredHtml"></div>
                                    <div v-else-if="emptyFilteredText">{{ emptyFilteredText }}</div>
                                </td>
                            </tr>

                            <template v-else-if="!loading && elements.length">
                                <tr v-for="(item, key) in elements" :key="key">
                                    <th v-if="selectable" scope="row" class="ps-4">
                                        <div class="form-check font-size-16">
                                            <input
                                                    v-model="item.selected"
                                                    type="checkbox"
                                                    class="form-check-input"
                                                    :id="`select-${_.uid}-${item[primaryKey]}`"
                                                    @change="checkSelectAll"
                                            />
                                            <label
                                                    class="form-check-label"
                                                    :for="`select-${_.uid}-${item[primaryKey]}`"
                                            ></label>
                                        </div>
                                    </th>
                                    <td v-for="(field, fieldKey) in fields" :key="fieldKey" :class="getTdClass(field.tdClass, field.key)">
                                        <slot :name="field.key" :item="item" :field="field" :value="item[field.key]" :methods="{ destroy: destroy }">
                                            <template v-if="field.key !== 'actions'">
                                                <template v-if="typeof item[field.key] === 'boolean'">
                                                    <i v-if="item[field.key] === true" class="fa fas fa-check-circle text-success"></i>
                                                    <i v-else class="fa fas fa-times-circle text-danger"></i>
                                                </template>
                                                <template v-else>
                                                    <template v-if="field.formatter">
                                                        <template v-if="typeof field.formatter(item[field.key], fieldKey, item) === 'boolean'">
                                                            <i v-if="field.formatter(item[field.key], fieldKey, item) === true" class="fa fas fa-check-circle text-success"></i>
                                                            <i v-else class="fa fas fa-times-circle text-danger"></i>
                                                        </template>
                                                        <template v-else>
                                                            {{ field.formatter(item[field.key], fieldKey, item) }}
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        {{ item[field.key] }}
                                                    </template>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <ul class="list-inline mb-0 column-actions no-wrap">
                                                    <slot name="more-action" :item="item" :fetch="fetch"></slot>
                                                    <li v-if="editable" class="list-inline-item">
                                                        <router-link
                                                                v-if="can(permission + '.update') && editLink"
                                                                :to="getEditLink(item.id)"
                                                                :title="$t('base.edit').ucFirst()"
                                                                class="px-2 text-primary"
                                                        >
                                                            <i class="bx bx-pencil font-size-18"></i>
                                                        </router-link>
                                                    </li>
                                                    <li v-if="deletable" class="list-inline-item">
                                                        <a
                                                                v-if="can(permission + '.delete')"
                                                                @click.prevent="destroy(item.id)"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-placement="top"
                                                                :title="$t('base.delete').ucFirst()"
                                                                class="px-2 text-danger cursor-pointer"
                                                        ><i class="bx bx-trash-alt font-size-18"></i
                                                        ></a>
                                                    </li>
<!--
                                                    <li class="list-inline-item dropdown">
                                                        <a
                                                                class="text-muted dropdown-toggle font-size-18 px-2"
                                                                href="#"
                                                                role="button"
                                                                data-bs-toggle="dropdown"
                                                                aria-haspopup="true"
                                                        >
                                                            <i class="bx bx-dots-vertical-rounded"></i>
                                                        </a>

                                                        <div class="dropdown-menu dropdown-menu-end">
                                                            <router-link v-if="editable && can(permission + '.update') && editLink" :to="getEditLink(item.id)" class="dropdown-item" href="#">
                                                                <div class="d-flex align-items-center">
                                                                    <i class="mdi mdi-pencil font-size-14 me-2"></i>
                                                                    <span>{{ $t('base.edit').ucFirst() }}</span>
                                                                </div>
                                                            </router-link>
                                                            <a v-if="can(permission + '.delete')" class="dropdown-item cursor-pointer" @click.prevent="destroy(item.id)">
                                                                <div class="d-flex align-items-center">
                                                                    <i class="mdi mdi-trash-can font-size-14 me-2"></i>
                                                                    <span>{{ $t('base.delete').ucFirst() }}</span>
                                                                </div>
                                                            </a>
                                                            <slot name="extra-actions" :item="item" :field="field"></slot>
                                                        </div>
                                                    </li>
-->
                                                </ul>
                                            </template>
                                        </slot>
                                    </td>
                                </tr>
                            </template>
                        </tbody>

                        <tfoot v-if="footClone" :class="footClasses">
                        <tr :class="footRowClasses">
                            <th class="ps-4" scope="col" style="width: 50px">
                                <div class="form-check font-size-16">
                                    <input
                                            id="contacusercheck"
                                            class="form-check-input"
                                            type="checkbox"
                                    />
                                    <label
                                            class="form-check-label"
                                            for="contacusercheck"
                                    ></label>
                                </div>
                            </th>
                            <th scope="col">Name</th>
                            <th scope="col">Position</th>
                            <th scope="col">Email</th>
                            <th scope="col">Projects</th>
                            <th scope="col" style="width: 200px">Action</th>
                        </tr>
                        </tfoot>

                        <caption v-if="hasCaption && captionHtml" :class="{'caption-top': captionTop}"
                                 v-html="captionHtml"></caption>
                        <caption v-else-if="hasCaption && caption" :class="{'caption-top': captionTop}">
                            <slot name="caption">
                                {{ caption }}
                            </slot>
                        </caption>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <!-- end row -->
    <paginator v-if="elements && elements.length" :pager="pager" @update-page="(number) => page = number"></paginator>
    <!-- end row -->
</template>

<script>
import Swal from "sweetalert2";
import Paginator from "./paginator.vue";
import _ from "lodash"
import draggable from "vuedraggable";

export default {
    name: "advanced-table",
    components: {draggable, Paginator},

    props: {
        moduleNamespace: {
            type: String
        },
        apiUrl: {
            type: String
        },
        bordered: {
            type: Boolean,
            default: false
        },
        borderless: {
            type: Boolean,
            default: false
        },
        busy: {
            type: Boolean,
            default: false
        },
        caption: {
            type: String
        },
        captionHtml: {
            type: String
        },
        captionTop: {
            type: Boolean,
            default: false
        },
        currentPage: {
            type: [String, Number],
            default: 1
        },
        dark: {
            type: Boolean,
            default: false
        },
        detailsTdClass: {
            type: [Object, Array, String]
        },
        emptyFilteredHtml: {
            type: String
        },
        emptyFilteredText: {
            type: String
        },
        emptyHtml: {
            type: String
        },
        emptyText: {
            type: String
        },
        fields: {
            type: Array,
            required: true
        },
        filter: {
            type: [Array, Object, String, RegExp]
        },
        filterDebounce: {
            type: [Number, String],
            default: 0
        },
        filterFunction: {
            type: Function
        },
        filterIgnoredFields: {
            type: Array,
            default: () => []
        },
        filterIncludedFields: {
            type: Array,
            default: () => []
        },
        fixed: {
            type: Boolean,
            default: false
        },
        footClone: {
            type: Boolean,
            default: false
        },
        footRowVariant: {
            type: String
        },
        footVariant: {
            type: String
        },
        headRowVariant: {
            type: String
        },
        headVariant: {
            type: String
        },
        hover: {
            type: Boolean,
            default: false
        },
        id: {
            type: String
        },
        items: {
            type: [Array, Function]
        },
        labelSortAsc: {
            type: String
        },
        labelSortClear: {
            type: String
        },
        labelSortDesc: {
            type: String
        },
        noBorderCollapse: {
            type: Boolean,
            default: false
        },
        noFooterSorting: {
            type: Boolean,
            default: false
        },
        noLocalSorting: {
            type: Boolean,
            default: false
        },
        noProviderFiltering: {
            type: Boolean,
            default: false
        },
        noProviderPaging: {
            type: Boolean,
            default: false
        },
        noProviderSorting: {
            type: Boolean,
            default: false
        },
        noSelectOnClick: {
            type: Boolean,
            default: false
        },
        noSortReset: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        perPage: {
            type: [Number, String],
            default: 0
        },
        primaryKey: {
            type: String,
            default: 'id'
        },
        responsive: {
            type: [Boolean, String],
            default: false
        },
        selectMode: {
            type: String,
            default: 'multi'
        },
        selectable: {
            type: Boolean,
            default: false
        },
        selectedVariant: {
            type: String
        },
        showEmpty: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        sortBy: {
            type: String
        },
        sortCompare: {
            type: Function
        },
        /*sortCompareLocale: {
            type: [Array, String]
        },
        sortCompareOptions: {
            type: Object,
            default: () => {
            } //numeric: true }
        },*/
        sortDesc: {
            type: Boolean,
            default: false
        },
        sortDirection: {
            type: String,
            default: 'asc'
        },
        sortIconLeft: {
            type: Boolean,
            default: false
        },
        /*sortNullLast: {
            type: Boolean,
            default: false
        },*/
        /*stacked: {
            type: [Boolean, String],
            default: false
        },*/
        /*stickyHeader: {
            type: [Boolean, String],
            default: false
        },*/
        sortable: {
            type: Boolean,
            default: false
        },
        striped: {
            type: Boolean,
            default: false
        },
        tableClass: {
            type: [Object, String]
        },
        tableVariant: {
            type: String
        },
        tbodyClass: {
            type: [Array, Object, String],
        },
        tbodyTrAttr: {
            type: [Object, Function],
        },
        tbodyTrClass: {
            type: [Array, Object, String, Function]
        },
        tbodyTransitionHandlers: {
            type: Object,
        },
        tbodyTransitionProps: {
            type: Object,
        },
        tfootClass: {
            type: [Array, Object, String]
        },
        tfootTrClass: {
            type: [Array, Object, String]
        },
        theadClass: {
            type: [Array, Object, String]
        },
        theadTrClass: {
            type: [Array, Object, String]
        },
        value: {
            type: Array,
            default: () => []
        },
        deletable: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: false
        },
        permission: {
            type: String
        },
        editLink: {
            type: [Object, String]
        },
        createLink: {
            type: [Object, String]
        },
        queries: {
            type: Object
        },
        searchable: {
            type: Boolean,
            default: false
        },
        toBeForwarded: {
            type: Object
        }
    },

    computed: {
        searchDebounce: {
            get: function () {
                return this.search;
            },
            set: function (value) {
                this.tempSearch = value
                _.debounce( (value) => {
                    if (this.tempSearch === value) {
                        this.search = value;
                    }
                }, 700)(value);
            },
        },

        sortingDisabled: function () {
            if (Array.isArray(this.items) && this.noLocalSorting) {
                return true
            }

            if (!Array.isArray(this.items) && this.noProviderSorting) {
                return true
            }

            return false
        },

        tableClasses: function () {
            const classes = {
                'table-bordered': this.bordered,
                'table-borderless': this.borderless,
                'table-dark': this.dark,
                'table-fixed': this.fixed,
                'table-hover': this.hover,
                'table-border-collapse': !this.noBorderCollapse,
                'table-border-separate': this.noBorderCollapse,
                'table-sm': this.small,
                'table-striped': this.striped,
                'border': this.outlined
            }

            if (this.tableVariant) {
                classes[`table-${this.tableVariant}`] = true
            }

            return this.mergeClass(classes, this.tableClass);
        },

        headClasses: function () {
            const classes = {}

            if (this.headVariant) {
                classes[`table-${this.headVariant}`] = true
            }

            return this.mergeClass(classes, this.theadClass);
        },

        bodyClasses: function () {
            const classes = {}

            if (this.tbodyClass) {
                classes[`table-${this.headVariant}`] = true
            }

            return this.mergeClass(classes, this.theadClass);
        },

        footClasses: function () {
            const classes = {}

            if (this.footVariant) {
                classes[`table-${this.footVariant}`] = true
            }

            return this.mergeClass(classes, this.tfootClass);
        },

        headRowClasses: function () {
            const classes = {}

            if (this.headRowVariant) {
                classes[`table-${this.headRowVariant}`] = true
            }

            return this.mergeClass(classes, this.theadTrClass);
        },

        footRowClasses: function () {
            const classes = {}

            if (this.footRowVariant) {
                classes[`table-${this.footRowVariant}`] = true
            }

            return this.mergeClass(classes, this.tfootTrClass);
        },

        hasCaption: function () {
            return !!this.$slots.caption || !!this.caption || this.captionHtml;
        },

        module: function () {
            return this.moduleNamespace && this.$store.hasModule(this.moduleNamespace)
        },

        pager: {
            get: function () {
                if (this.module) {
                    return this.$store.getters[`${this.moduleNamespace}/pager`];
                }

                return {}
            },
        },

        elements: {
            get: function () {
                if (this.items) {
                    return Array.isArray(this.items) ? this.litems : this.items(this.params);
                }
                else if (this.module) {
                    return JSON.parse(JSON.stringify(this.$store.getters[`${this.moduleNamespace}/list`]));
                } else {
                    return this.rows;
                }
            },
            set: function (value) {
                if (this.sortable) {
                    this.$store.dispatch(`${this.moduleNamespace}/sortList`, value)
                }
                if (!this.module && this.apiUrl) {
                    this.rows = value;
                }
            },
        },

        selected: function () {
            return this.elements.filter(item => item.selected)
        },

        loading: function () {
            if (this.module) {
                return this.$store.getters[`${this.moduleNamespace}/loading`];
            } else {
                return this.apiLoading || this.busy
            }
        },

        params: function () {
            const params = {
                page: this.page,
                per_page: this.limit,
                sorting: {
                    column: this.sortCol,
                    desc: this.sortDir === 'desc'
                },
                search: this.search
            }

            return this.queries
                ? Object.assign({}, this.queries, params)
                : params
        },

        editLinkPath: function () {
            if (!this.editLink) {
                return ''
            }

            const target = this.$router.options.routes[0].children.find(route => route.name === this.editLink.name);

            return 'routes.' + target.path
        },

        translatedCreateLink: function () {
            if (!this.createLink) {
                return ''
            }

            const target = this.$router.options.routes[0].children.find(route => route.name === this.createLink.name);

            return { path: this.$t('routes.' + target.path, this.createLink.params) }
        },
    },

    data: function () {
        return {
            litems: this.items,
            rows: [],
            apiLoading: false,
            page: this.$route.query.page || this.currentPage,
            limit: this.$route.query.per_page || this.perPage,
            search: null,
            tempSearch: null,
            sortCol: (this.$route.query.sorting && this.$route.query.sorting.column) || this.sortBy,
            sortDir: (this.$route.query.sorting && this.$route.query.sorting.desc) || (this.sortDesc ? 'desc' : this.sortDirection),
            selectAll: false,
            searchFocused: false
        }
    },

    methods: {
        checkSelectAll: function () {
            this.selectAll = this.elements.every(item => item.selected)
        },

        getThClass: function (fieldClasses, column) {
            const classes = {
                'cursor-pointer': !this.sortingDisabled && this.checkSorting(column),
                'flex-row-reverse justify-content-end': this.sortIconLeft,
                'justify-content-between': !this.sortIconLeft
            }

            return this.mergeClass(classes, fieldClasses);
        },

        getTdClass: function (fieldClasses, column) {
            const classes = {
                'column-actions': column === 'actions'
            }

            return this.mergeClass(classes, fieldClasses)
        },

        getEditLink: function (id) {
            return Object.assign({path: this.$t(this.editLinkPath, Object.assign({ id: id }, this.toBeForwarded) ).rmValidation()})
        },

        checkSorting: function (column) {
            return this.fields.find(field => field.key === column).sortable
        },

        changeSort: function (column) {
            if (!this.sortingDisabled && !this.checkSorting(column)) {
                return
            }

            if (this.sortCol === column) {
                this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc';
            } else {
                this.sortCol = column;
                this.sortDir = 'asc';
            }

            if (this.litems) {
                if (this.sortCompare) {
                    this.litems.sort(this.sortCompare)
                } else {
                    this.litems.sort((a, b) => {
                        if (typeof a[column] === 'number' && typeof b[column] === 'number') {
                            return (this.sortDir === 'asc') ? a[column] - b[column] : b[column] - a[column]
                        } else {
                            return (this.sortDir === 'asc') ? (a[column].localeCompare(b[column]) ) : (b[column].localeCompare(a[column]));
                        }
                    })
                }
            }
        },

        sortLabel: function (column) {
            if (this.sortCol === column && this.sortDir === 'asc') {
                return this.labelSortDesc
            }

            return this.labelSortAsc
        },

        sortClass: function (column) {
            let cl = this.sortIconLeft ? 'me-2 ' : ''

            if (this.sortCol === column) {
                return cl + 'sort-' + this.sortDir
            }

            return cl + 'sort-neutral'
        },

        mergeClass: function (base, additional) {
            if (!additional) {
                return base
            }

            if (typeof additional === 'object') {
                return Object.assign(base, additional)
            }

            if (typeof additional === 'string') {
                let classes = additional.split(' ')
                classes.forEach(cl => {
                    base[cl] = true
                })

                return base
            }

            return base
        },

        fetch: function () {
            if (this.module) {
                this.$store.dispatch(this.moduleNamespace + '/list', this.params)
            } else if (this.apiUrl) {
                this.apiLoading = true
                this.axios.get(this.apiUrl, this.params)
                    .then(response => {
                        this.rows = response.data.data
                    })
                    .catch(e => {
                        console.log(e)
                    })

            }
        },

        removeItem: function (id) {
            const index = this.litems.findIndex(item => item.id === id)

            if (index) {
                this.litems.splice(index, 1)
            }
        },

        destroySuccess: function () {
            Swal.fire({
                icon: 'success',
                title: this.$t('base.delete_successful'),
                customClass: {
                    confirmButton: 'btn btn-success'
                }
            })
        },

        destroy: function (id) {
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.delete_confirm_title'),
                text: this.$t('base.delete_confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-danger border-danger',
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.delete').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    if (this.module) {
                        this.$store.dispatch(this.moduleNamespace + '/destroy', id)
                            .then(() => {
                                this.destroySuccess()
                            })
                            .catch(e => {
                                console.log(e)
                            })
                    } else if (this.apiUrl) {
                        this.apiLoading = true
                        this.axios.post(`${this.apiUrl}/${id}`, {_method: 'DELETE'})
                            .then(() => {
                                this.removeItem()
                                this.destroySuccess()
                            })
                            .catch(e => {
                                console.log(e)
                            })

                    }
                }
            });
        },

        saveSorting: function () {
            this.$store.dispatch(this.moduleNamespace + '/saveSorting')
        },
    },

    watch: {
        selectAll: function (value) {
            if (value === true || (value === false && this.elements.every(item => item.selected))) {
                this.elements.forEach((item, key) => {
                    this.elements[key].selected = value
                });
            }
        },

        params: {
            deep: true,
            handler: function () {
                this.$router.push(Object.assign({}, {path: this.$route.path, query: this.params}))
                this.fetch()
            },
        }
    },

    mounted() {
        this.fetch();
    }
}
</script>

<style scoped>
    .column-actions {
        min-width: 80px;
        width: 1%;
    }
</style>
