<template>
    <div class="row g-0 align-items-center pb-4">
        <div class="col-sm-6">
            <div>
                <p class="mb-sm-0">{{
                        $t('base.table_show_from_to', {
                            from: pager.from,
                            to: pager.to,
                            total: pager.total
                        }).ucFirst()
                    }}</p>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="float-sm-end">
                <ul class="pagination mb-sm-0">
                    <li class="page-item" :class="{disabled: prevDisabled, 'cursor-pointer': !prevDisabled}">
                        <a class="page-link" @click.prevent="!prevDisabled && updatePage(1)">
                            <i class="mdi mdi-chevron-left"></i>
                        </a>
                    </li>
                    <li v-if="prevRangeEnable" class="page-item cursor-pointer" @click.prevent="prevRange">
                        <a class="page-link" href="#">...</a>
                    </li>
                    <li v-for="(page, key) in pages" class="page-item" :class="{'active user-select-none': pager.current_page === page, 'cursor-pointer': pager.current_page !== page}" :key="key" @click.prevent="( pager.current_page !== page) && updatePage(page)">
                        <a class="page-link">{{ page }}</a>
                    </li>
                    <li v-if="nextRangeEnable" class="page-item cursor-pointer" @click.prevent="nextRange">
                        <a class="page-link">...</a>
                    </li>
                    <li class="page-item" :class="{disabled: nextDisabled, 'cursor-pointer': !nextDisabled}">
                        <a class="page-link" href="#" @click.prevent="!nextDisabled && updatePage(pager.last_page)">
                            <i class="mdi mdi-chevron-right"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "paginator",

    props: {
        pager: {
            type: Object,
            required: true,
        }
    },

    computed: {
        prevDisabled: function () {
            return this.pager.current_page === 1
        },

        nextDisabled: function () {
            return this.pager.current_page === this.pager.last_page
        },

        prevRangeEnable: function () {
            return 1 < this.pages[0]
        },

        nextRangeEnable: function () {
            return this.pager.last_page > this.pages[this.pages.length - 1]
        },

        pages: function () {
            return this.range(this.pager.current_page)
        },
    },

    methods: {
        updatePage: function (page) {
            this.$emit('update-page', page)
        },

        nextRange: function () {
            let plus = 0;

            for (let n = 1; n <= 4; n++) {
                if (this.pager.current_page + n < this.pager.last_page) {
                    plus = n
                }
            }

            this.$emit('update-page', this.pager.current_page + plus)
        },

        prevRange: function () {
            let minus = 1;

            for (let n = 1; n <= 4; n++) {
                if (this.pager.current_page - n > 0) {
                    minus = n
                }
            }

            this.$emit('update-page', this.pager.current_page - minus)
        },

        range: function (start, steps = 2, direction = 'both') {
            const result = []

            if (direction === 'both' || direction === 'down') {
                for (let i = steps; i > 0; i--) {
                    let number = start - i
                    if (number > 0) {
                        result.push(number)
                    }
                }
            }

            result.push(start)

            if (direction === 'both' || direction === 'up') {
                for (let i = 1; i <= steps; i++) {
                    let number = start + i
                    if (number > 0 && number <= this.pager.last_page) {
                        result.push(number)
                    }
                }
            }

            return result
        },
    }
}
</script>

<style scoped>

</style>
